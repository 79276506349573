import { ApolloClient, InMemoryCache, from } from '@apollo/client';
import { httpLink, authLink, testLink, currentTimeLink, logoutLink } from "./links"


export { gql } from '@apollo/client';

export const client = new ApolloClient({
  //uri: 'https://48p1r2roz4.sse.codesandbox.io',
  cache: new InMemoryCache(),
  link: from([
    logoutLink,
    testLink,
    currentTimeLink,
    authLink,
    httpLink
  ])
});

// const client = ...

// client
//   .query({
//     query: gql`
//       query GetRates {
//         rates(currency: "USD") {
//           currency
//         }
//       }
//     `
//   })
//   .then(result => console.log(result));