import {
  SET_STRIPE,
  TOGGLE_MODE,
  CHANGE_PASSWORD_REQUEST,
  CHANGE_PASSWORD_SUCCESS,
  CHANGE_PASSWORD_ERROR,
  CONTACT_REQUEST,
  CONTACT_SUCCESS,
  CONTACT_ERROR,
  SET_HISTORY,
  REDIRECT
} from "../configurations/Types";
//import rootModal from '../commons/modals/rootModal';

import { createAction } from "@reduxjs/toolkit";

export const setPushToken = createAction("SET_PUSH_TOKEN");

export function setStripe(payload) {
  return {
    type: SET_STRIPE,
    payload
  };
}

export function toggleMode() {
  return {
    type: TOGGLE_MODE
  };
}

//Changing password from within the app
export function changePasswordRequest(values, cb, extras) {
  //let newData = {...values, email:extras}
  return {
    type: CHANGE_PASSWORD_REQUEST,
    body: values,
    id: extras,
    cb
  };
}

//Could use callbacks in success and error for loading bars or messages or could handle everything in reducer
export function changePasswordSuccess(payload) {
  return {
    type: CHANGE_PASSWORD_SUCCESS,
    payload: payload
  };
}

export function changePasswordError(errorInfo) {
  return {
    type: CHANGE_PASSWORD_ERROR,
    payload: errorInfo
  };
}

// Contact Actions:
export function contactRequest(body, cb) {
  return {
    type: CONTACT_REQUEST,
    body,
    cb
  };
}

export function contactSuccess() {
  return {
    type: CONTACT_SUCCESS
  };
}

export function contactError(errorInfo) {
  return {
    type: CONTACT_ERROR,
    payload: errorInfo
  };
}

export function setHistory(history) {
  return {
    type: SET_HISTORY,
    payload: history
  };
}

export function redirect(url) {
  return {
    type: REDIRECT,
    payload: url
  };
}
