import React from "react";
import { Layout } from "antd";
import styled, { css } from "styled-components";

import baseStyle from "./Style";

const { Content } = Layout;

export const StyledContent = css`
  ${baseStyle}
  background: ${(props) => props.theme[props.theme.mode].background.primary};

  padding: 2.8rem ${(props) => props.theme[props.theme.mode].spacing.header};
  /* ${(props) =>
    props.externalscreen
      ? `
    padding-top: 2rem;
    `
      : null} */

  margin-top: ${(props) =>
    props.externalscreen
      ? "0px"
      : props.theme[props.theme.mode].spacing.header};

  min-height: ${(props) =>
    props.scroll
      ? "100vh"
      : `calc(100vh - ${props.theme[props.theme.mode].spacing.header})`};
  /* min-height: ${(props) =>
    props.scroll
      ? `calc(100vh + ${props.theme[props.theme.mode].spacing.header})`
      : `calc(100vh - ${props.theme[props.theme.mode].spacing.header})`}; */

  margin-left: ${(props) =>
    props.externalscreen
      ? "0px"
      : `${props.theme[props.theme.mode].spacing.sidebar}`};

  ${(props) => (props.flex ? "display:flex; flex-direction: column;" : "")};
  ${(props) => (props.centered ? "justify-content: center;" : "")};

  hr.stepsLine {
    width: 14%;
    height: 0.2vw;
    background-color: ${(props) => props.theme[props.theme.mode].primary.main};
    position: relative;
    margin: 0;
    margin-left: 1vw;
    margin-right: auto;
  }
  hr.stepsLine:after {
    content: "";
    width: 0.45vw;
    height: 0.45vw;
    background-color: ${(props) => props.theme[props.theme.mode].common.white};
    position: absolute;
    top: 0;
    bottom: 0;
    margin: auto;
    left: auto;
    right: -2px;
    border-radius: 50%;
    border: 1px solid ${(props) => props.theme[props.theme.mode].primary.main};
  }
  /* @Need to remove this block */
  .mainContentDiv {
    margin-bottom: ${(props) => props.theme[props.theme.mode].spacing.outer}px;
  }
  .mainContentDivSmall {
    margin-bottom: ${(props) => props.theme[props.theme.mode].spacing.inner}px;
  }

  .mainContentDivMedium {
    margin-bottom: ${(props) =>
      props.theme[props.theme.mode].spacing.inner + 4}px;
  }
  /* @Need to remove this block */

  /* .ant-select-selection {
    padding: 0.7vw 0.2vw;
  } */

  .messageBoxTitle {
    padding-left: 0.1em !important;
  }
  .messageBoxTitleHeading,
  .h3 {
    font-size: 1.05rem;
    font-weight: 700;
    margin-bottom: 0em !important;
  }
  .messageBoxTitleDate,
  .span {
    font-size: 0.76rem;
    font-weight: 700;
    color: ${(props) => props.theme[props.theme.mode].textColor.hint};
  }
  .messageBoxText,
  .p {
    margin-top: 1em !important;
    margin-bottom: 2em !important;
  }
  .flagBoxReasonText,
  .p {
    font-size: 0.76rem;
    margin-top: 1em !important;
  }
  .flagBoxReportText,
  .p {
    font-size: 0.76rem;
    margin-top: 0.5em !important;
  }
  .greyBackground {
    background-color: #f6f6f6;
    border-radius: 10px;
  }
  .dashedBorder {
    border-style: dotted;
  }
  .break {
    word-break: break-all;
  }
  .signature {
    width: 100%;
    height: 100%;
    min-height: 20rem;
  }
  /* Reharts Pie Charts */
  .recharts-wrapper .recharts-legend-wrapper ul.recharts-default-legend {
    display: flex;
    flex: 1;
    flex-wrap: nowrap;
    justify-content: space-around;
  }
  .recharts-wrapper
    .recharts-legend-wrapper
    ul.recharts-default-legend
    li.recharts-legend-item {
    display: flex !important;
    flex: 1;
    flex-wrap: nowrap;
    justify-content: center;
    align-items: center;
    margin-right: 5px !important;
  }
  .recharts-wrapper
    .recharts-legend-wrapper
    ul.recharts-default-legend
    li.recharts-legend-item
    svg {
    width: 25%;
    height: 25%;
    margin-right: -5px !important;
  }
`;

const ContentStyle = styled(Content)`
  ${StyledContent}
`;

const ContentWrapper = (props) => {
  return <ContentStyle {...props}></ContentStyle>;
};

export default ContentWrapper;
