import { createAction } from "@reduxjs/toolkit";
export const getReport = createAction("GET_REPORT", function prepare(body, cb) {
    return {
      payload: {
        body: {
            data: body
        },
        cb
      }
    }
  });
