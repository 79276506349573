import { combineReducers } from "redux";
import appReducer from "./App";
import modalReducer from "./Modal";
import authReducer from "./Auth";

export default combineReducers({
  App: appReducer,
  Auth: authReducer,
  Modal: modalReducer,
});

// lazmi krna hai
// function combine(state, action) {
//     if(action.type === "logout"} {
//         state = null;
//     }
// }
