import { all } from "redux-saga/effects";
import { watchAppActions } from "./App";
import { watchAuthActions } from "./Auth";
import { watchImagesActions } from "./Images";
import { watchReportActions } from "./Report";

export default function* rootSaga() {
  // console.log("roots saga running")
  yield all([
    watchAppActions(),
    watchAuthActions(),
    watchImagesActions(),
    watchReportActions(),
  ]);
}
