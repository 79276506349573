import React from 'react';
import { connect } from "react-redux";

//import { Modal } from 'antd';
import { StyledContent } from "../maincontent/index"
import BasicModal from "./Basic"
import styled from "styled-components"


class RootModal extends React.Component {

  modalComponent = {
    basic_modal: BasicModal
  }

  render() {
    if (!this.props.modals.length > 0) {
      return null;
    }
    const ModalsToRender = this.props.modals.map((val, ind) => {
      let ModalToRender = this.modalComponent[val.modalType];
      return <ModalToRender key={ind} {...val.modalProps} className={this.props.className +  " " + val.modalProps.className} />
    })
    return (
      <div>
        {ModalsToRender.map((val) => val)}
      </div>
    );
  }
}

const StyledModal = styled(RootModal)`
  ${StyledContent}
  /* width: 400px; */
  // margin: 0;
  margin: 4rem 0;
  padding: 0;
  min-height: auto;

  /* background: white; */
  background: transparent;

  /* border-radius: 10px; */

  /* overflow: hidden; */
  color: ${props => props.theme[props.theme.mode].textColor.dark};
  /* color: ${props => props.theme[props.theme.mode].textColor.primary}; */

  &.overflow {
    overflow: visible
  }

  &.sharpBorder {
    border-radius: 0;
  }

  &.sharpBorder .ant-modal-content {
    border-radius: 0;
  }

  &.bordered {
    border: 1px solid white;
  }

  &.small {
    width: 25vw !important;
  }

  &.wide {
    width: 40vw !important;
  }

  &.veryWide {
    width: 55vw !important;
  }

  &.extremelyWide {
    width: 80vw !important;
  }

  &.visibleCloseButton .ant-modal-close {
    top: 10px !important;
    right: 10px !important;
  }

  &.visibleCloseButton .ant-modal-close-x {
    background: #ffffff8c;
    border-radius: 100px;
    height: 30px;
    width: 30px;
    line-height: 30px;
  }

  /* &.appBackground {
    background: ${props => props.theme[props.theme.mode].background.primary};
    color: ${props => props.theme[props.theme.mode].textColor.primary};
  }

  &.appBackground .ant-modal-header {
    background: ${props => props.theme[props.theme.mode].secondary.main};
  }

  &.appBackground .ant-modal-footer button:first-child:hover{
    color: ${props => props.theme[props.theme.mode].secondary.main};
    border-color: ${props => props.theme[props.theme.mode].secondary.main};
  }

  &.appBackground .ant-modal-footer button:last-child {
    background-color: ${props => props.theme[props.theme.mode].primary.main};
    border: transparent;
  }

  &.appBackground .ant-modal-content {
    background: ${props => props.theme[props.theme.mode].background.primary};
  }

  &.appBackground .ant-modal-body{
    background: ${props => props.theme[props.theme.mode].background.primary};
  }

  &.appBackground .ant-modal-content button.button i {
    color: ${props => props.theme[props.theme.mode].common.white};
  }
  &.appBackground .ant-modal-content i {
    color: ${props => props.theme[props.theme.mode].textColor.primary};
  } */

  @media screen and (max-width: 991px) {
    && {
      padding: 0;
      width: 65vw !important;
      margin: 0;
    }
  }
  @media screen and (max-width: 767px) {
    && {
      background-color: transparent;
      width: 100% !important;
    }
  }
  & .ant-modal-header {
    background: ${props => props.theme[props.theme.mode].background.primary};
    color: ${props => props.theme[props.theme.mode].textColor.primary};
    border-color: ${props => props.theme[props.theme.mode].border}
  }

  & .ant-modal-title {
    color: ${props => props.theme[props.theme.mode].textColor.primary};
  }

  & .ant-modal-content {
    background: ${props => props.theme[props.theme.mode].background.primary};

    border-radius: 5px;
    overflow: hidden;
  }
  
  & .ant-modal-body {
    font-size: unset;
    padding: 5%;
    background: ${props => props.theme[props.theme.mode].background.primary};
    /* border-radius: 5px; */
  }

  &.smallPadding .ant-modal-body {
    padding: 1.5rem;
    /* border-radius: 5px; */
  }

  &.noPadding .ant-modal-body {
    padding: 0;
  }

  &.flex .ant-modal-body {
    display: flex;
  }

  &.noPadding .ant-modal-body {
    padding: 0;
  }

  &.flex .ant-modal-body {
    display: flex;
  }

  & .ant-modal-footer {
    //background: ${props => props.theme[props.theme.mode].background.primary};
    border-color: ${props => props.theme[props.theme.mode].border}
  }
`;

const ModalWrapper = props => {
  return (
    <StyledModal {...props}></StyledModal>
  );
};

export default connect((state) => state.Modal)(ModalWrapper);
