/// Constants
// export const GOOGLE_API_KEY = "AIzaSyAFnHI7y7n5eV9J-gHk-Ald5W9bnzKqCgI";
// export const STRIPE_PUBLISHABLE_API_KEY =
//   "pk_test_jUPgEi4tIpr7lEDkWC1xegOo00Trmps34D";
//export const STRIPE_SECRET_API_KEY = "pk_test_rBeOTa2VkXZ16aKpzBQiA8qT00SmODzsPk";
export const DEFAULT_TABLE_PAGE_SIZE = 10;
export const LOCAL_STORAGE_KEY = process.env.REACT_APP_LOCAL_STORAGE_KEY;
//"waruauth";
export const protocol_head = "https://";
export const current_sprint = 2;

// API base URL
export const API_BASE_URL = process.env.REACT_APP_API_BASE_URL_UAT;
export const API_GRAPHQL_URL = API_BASE_URL + process.env.REACT_APP_GRAPHQL_URL;
export const API_REST_URL = API_BASE_URL + process.env.REACT_APP_REST_URL;
export const USERS_URL = "/users";
//export const AUTH_URL = "/card";
// export const USER_URL = "/user";

//API endpoints

//App
// export const FAQS_ENDPOINT = "/get-faq";
export const CONTACT_ENDPOINT = "/contact";

//Auth
export const REGISTER_ENDPOINT = "/register";
export const SIGNUP_VALIDATION_REQUEST_ENDPOINT = "/signup-validation";
export const LOGIN_ENDPOINT = "/login";
export const VERIFY_EMAIL_ENDPOINT = "/resend-verify-email";
export const FORGOT_PASSWORD_ENDPOINT = "/request-password-reset";
export const VERIFY_CODE_ENDPOINT = "/verify-code";
export const RESET_PASSWORD_ENDPOINT = "/reset-password";

//Edit Profile
export const UPDATE_PROFILE_ENDPOINT = "/profile";
export const CHANGE_PASSWORD_ENDPOINT = "/change-password";
//change password api is in the app saga
//Edit Profile

export const UPLOAD_IMAGE_ENDPOINT = "/upload-image";
export const GET_REPORT_ENDPOINT = "/generate-pdf";
