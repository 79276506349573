import { HttpLink, ApolloLink } from "@apollo/client";
import { onError } from "@apollo/client/link/error";
import { logout } from "../../../utils";
import info from "components/message";
import moment from "moment";
import { LOCAL_STORAGE_KEY, API_GRAPHQL_URL } from "../../Constants";

export const httpLink = new HttpLink({ uri: API_GRAPHQL_URL });

export const authLink = new ApolloLink((operation, forward) => {
  const userToken = localStorage.getItem(LOCAL_STORAGE_KEY);

  const tokenObj = userToken ? JSON.parse(userToken) : undefined;

  // add the authorization to the headers
  operation.setContext(({ headers = {} }) => ({
    headers: {
      ...headers,
      //authorization: localStorage.getItem(LOCAL_STORAGE_KEY) || null,
      ...(tokenObj
        ? {
            Authorization: tokenObj ? "Bearer " + tokenObj.access_token : "",
            //user_id: userToken ? userToken.user_id : ""
          }
        : {}),
    },
  }));

  return forward(operation);
});

export const currentTimeLink = new ApolloLink((operation, forward) => {
  let { operationName, variables } = operation;

  if(operationName === "SubmitForm" || operationName === "UpdateForm") {
    variables.currentTime = moment().format("YYYY-MM-DD HH:mm:ss");
  }

  return forward(operation);
});

export const testLink = new ApolloLink((operation, forward) => {
  console.log("LINK-TEST");
  return forward(operation).map((response) => {
    const context = operation.getContext();
    // const {
    //   response: r
    // } = context

    console.log("LINK-TEST", context);

    // if (headers) {
    //   const refreshToken = headers.get('refreshToken')
    //   if (refreshToken) {
    //     localStorage.setItem(AUTH_TOKEN, refreshToken)
    //   }
    // }

    return response;
  });
});

export const logoutLink = onError(({ graphQLErrors, operation }) => {
  console.log("LINK-ERROR", graphQLErrors, operation);
  info(
    "error",
    (graphQLErrors && graphQLErrors[0] && graphQLErrors[0].message) ||
      "Error occured"
  );
  if (
    graphQLErrors &&
    graphQLErrors[0] &&
    graphQLErrors[0].debugMessage === "Unauthenticated." 
    //&&
    //operation.operationName !== "SET_TOKEN"
  )
    logout(true);
});
