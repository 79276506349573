import firebase from "firebase";

var firebaseConfig = {
  apiKey: "AIzaSyBO5HUyrOZAnL9Q_wSacwBEOdzNjQXTk3U",
  authDomain: "notification-test-e3eb4.firebaseapp.com",
  databaseURL: "https://notification-test-e3eb4.firebaseio.com",
  projectId: "notification-test-e3eb4",
  storageBucket: "notification-test-e3eb4.appspot.com",
  messagingSenderId: "802076664281",
  appId: "1:802076664281:web:a6fa95a9c3aa9ad28ef9c8",
  measurementId: "G-7N9R8799SB"
};

// Initialize Firebase
firebase.initializeApp(firebaseConfig);

const messaging = firebase.messaging();

export { messaging };