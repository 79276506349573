import React from "react";
import { put, takeLatest, cancelled } from "redux-saga/effects";
import { client, gql } from "configurations/apollo";
import {
  REGISTER_REQUEST,
  LOGIN_REQUEST,
  PASSWORD_RESET_REQUEST,
  RESEND_CODE_REQUEST,
  VERIFY_TOKEN_REQUEST,
  RESET_PASSWORD_REQUEST,
  RESEND_EMAIL,
  SIGNUP_VALIDATION_REQUEST,
} from "../configurations/Types";
import {
  registerSuccess,
  registerError,
  loginError,
  passwordResetSuccess,
  passwordResetError,
  resendCodeSuccess,
  resendCodeError,
  verificationCodeSuccess,
  verificationCodeError,
  changePasswordSuccess,
  changePasswordError,
  resendEmailRequest,
  signupValidationError,
  signupValidationSuccess,
} from "../actions/Auth";
import info from "../components/message/index";
import VerifyAccountMessage from "../appcomponents/shares/auth/VerifyAccountMessage";
import { login } from "../utils/index";
import { store } from "../ConfigureStore";
import {
  //LOGIN_ENDPOINT,
  FORGOT_PASSWORD_ENDPOINT,
  //VERIFY_CODE_ENDPOINT,
  //RESET_PASSWORD_ENDPOINT,
  VERIFY_EMAIL_ENDPOINT,
  // REGISTER_ENDPOINT,
  SIGNUP_VALIDATION_REQUEST_ENDPOINT,
} from "../configurations/Constants";

import { USERS_URL } from "../configurations/Constants";

import moment from "moment";

import { publicAgent } from "../configurations/AxiosAgent";

// const delay = ms => new Promise((res, rej) => setTimeout(() => res(5), ms));

const registerApi = (body) => {
  //console.log("registerApi", body);
  let current_time = moment().format("YYYY-MM-DD HH:mm:ss");
  return publicAgent.post(`${USERS_URL}`, {
    ...body,
    user_type: "user",
    // current_time:"2019-12-06 03:05:16"
    current_time,
  });
};

const signupValidationApi = (body) => {
  // console.log("signupValidationApi", body);
  return publicAgent.post(`${USERS_URL}${SIGNUP_VALIDATION_REQUEST_ENDPOINT}`, {
    email: body.email,
  });
};

const LOGIN_FRAGMENT = gql`
  fragment UserDetailsFragment on AuthPayload {
    access_token
    expires_in
    token_type
    user {
      _id
      role {
        _id
      }
      full_name
      username
      email
      device_token
      status
      created_at
    }
  }
`;

const LOGIN_MUTATION = gql`
  mutation Login($input: LoginInput!) {
    login(input: $input) {
      ...UserDetailsFragment
    }
  }
  ${LOGIN_FRAGMENT}
`;

const loginApi = (body) => {
  return client.mutate({
    mutation: LOGIN_MUTATION,
    variables: {
      input: {
        username: body.email,
        password: body.password,
        device_token: "",
        current_time: moment().format("YYYY-MM-DD HH:mm:ss"),
      },
    },
  });
  // return publicAgent.post(`${USERS_URL}${LOGIN_ENDPOINT}`, {
  //   ...body,
  // });
  // .then(res=>{
  //   console.log("RES Login",res)
  // });
};

const resendEmailApi = (email) => {
  return publicAgent.post(`${USERS_URL}${VERIFY_EMAIL_ENDPOINT}`, {
    email,
  });
};

const FORGOT_PASSWORD_FRAGMENT = gql`
  fragment ForgotPasswordFragment on ForgotPasswordResponse {
    status
    message
  }
`;

const FORGOT_PASSWORD_MUTATION = gql`
  mutation ForgotPassword($input: ForgotPasswordInput!) {
    forgotPassword(input: $input) {
      ...ForgotPasswordFragment
    }
  }
  ${FORGOT_PASSWORD_FRAGMENT}
`;

const passwordResetApi = (body) => {
  return client.mutate({
    mutation: FORGOT_PASSWORD_MUTATION,
    variables: {
      input: {
        email: body.email,
        current_time: moment().format("YYYY-MM-DD HH:mm:ss"),
      },
    },
  });
  //console.log("body passwordResetApi", body);
  // return publicAgent.post(`${USERS_URL}${FORGOT_PASSWORD_ENDPOINT}`, {
  //   ...body,
  // });
};
const resendCodeApi = (body) => {
  // return publicAgent.post(`${USERS_URL}${FORGOT_PASSWORD_ENDPOINT}`, {
  //   ...body,
  // });
  return client.mutate({
    mutation: FORGOT_PASSWORD_MUTATION,
    variables: {
      input: {
        email: body.email,
        current_time: moment().format("YYYY-MM-DD HH:mm:ss"),
      },
    },
  });
};

const VERIFY_CODE_FRAGMENT = gql`
  fragment VerifyCodeFragment on VerifyCodeResponse {
    status
    message
  }
`;

const VERIFY_CODE_MUTATION = gql`
  mutation VerifyCode($input: VerifyCode!) {
    verifyCode(input: $input) {
      ...VerifyCodeFragment
    }
  }
  ${VERIFY_CODE_FRAGMENT}
`;

const verifyCodeApi = (body) => {
  return client.mutate({
    mutation: VERIFY_CODE_MUTATION,
    variables: {
      input: {
        email: body.email,
        code: body.code,
        current_time: moment().format("YYYY-MM-DD HH:mm:ss"),
      },
    },
  });
  // console.log("verifyCodeApi: ", body);
  // return publicAgent.post(`${USERS_URL}${VERIFY_CODE_ENDPOINT}`, {
  //   ...body,
  // });
};

const RESET_PASSWORD_FRAGMENT = gql`
  fragment ResetPasswordFragment on ForgotPasswordResponse {
    status
    message
  }
`;

const RESET_PASSWORD_MUTATION = gql`
  mutation ResetPassword($input: NewPasswordInput!) {
    updateForgottenPassword(input: $input) {
      ...ResetPasswordFragment
    }
  }
  ${RESET_PASSWORD_FRAGMENT}
`;

const resetPasswordApi = (body) => {
  return client.mutate({
    mutation: RESET_PASSWORD_MUTATION,
    variables: {
      input: {
        email: body.email,
        password: body.password,
        //password_confirmation: body.confirm_password,
        current_time: moment().format("YYYY-MM-DD HH:mm:ss"),
      },
    },
  });
  // console.log("resetPasswordApi 104: ", body, RESET_PASSWORD_ENDPOINT);
  // let resetPasswordFields ={
  //   new_password :body.password
  // }
  // let { password, ...rest } = body;
  // return publicAgent.post(`${USERS_URL}${RESET_PASSWORD_ENDPOINT}`, {
  //   ...rest,
  //   new_password: password,
  // });
};

export function* register(action) {
  let res;
  try {
    //   console.log("FROM SAGA", action);
    let {
      errors,
      termsAndConditions,
      confirm_password,
      ...properBody
    } = action.body;
    // properBody.dob = moment(properBody.dob).format("YYYY-MM-DD");
    // console.log("EXTRACTED BODY", properBody);
    res = yield registerApi({ ...properBody, ...action.extras });
    // console.log("FROM SAGA 2: ", res);
    info("info", `Please verify your email so we can continue login`);
    yield put(registerSuccess(res));
    if (action.cb) action.cb();
  } catch (e) {
    // console.log("IN CATCH");
    try {
      // console.log("FROM SAGA ERROR", e.response.data.error.message);
      info("error", `${e.response.data.error.message}`);
    } catch (e) {}
    yield put(registerError(e));
  } finally {
    if (yield cancelled()) {
      // console.log("CANCELLED");
    }
  }
}
//signup validation saga
export function* signUpValidation(action) {
  let res;
  try {
    // console.log("From Saga signUpValidation");
    res = yield signupValidationApi(action.body);
    //console.log("signUpValidation", res);
    if (res.data.error.message === "Email already exists.") {
      info("error", `${res.data.error.message}`);
    }

    yield put(signupValidationSuccess(res));
  } catch (e) {
    yield put(signupValidationError(e));
    // info("warning", `${res.data.data.message}`);
  } finally {
    if (yield cancelled()) {
      // console.log("CANCELLED");
    }
  }
}

export function* authorize({ body, cb, extras }) {
  let res;
  try {
    //  console.log("FROM SAGA", body, extras);
    res = yield loginApi(body);
    console.log("FROM SAGA", res);
    login(res.data.login, cb);
    console.log("LOGIN API SUCCESS: ", res);
    // yield put(loginSuccess(res));
  } catch (e) {
    // if (e.response) {
    //   // console.log("e.response.data.status", e);
    //   if (e.response.data.status === 400) {
    //     //VerifyAccountMessage();
    //     // console.log("aut0rize if");
    //     if (e.response.data.error.message === "Please verify your email.") {
    //       // console.log("please verify eamil........");
    //       info(
    //         "info",
    //         <VerifyAccountMessage
    //           email={body.email}
    //           verifyRedirect={() =>
    //             store.dispatch(resendEmailRequest(body.email))
    //           }
    //         />,
    //         10
    //       );
    //       //yield put(resendEmailRequest(body.email));
    //     } else {
    //       // console.log("else:",``)
    //       //console.log("FROM SAGA ERROR", e.response.data.error.message);
    //       info("error", `${e.response.data.error.message}`);
    //     }
    //   }
    // }
    console.log("Login Error: ", e, e.data);
    // info("error", `Error occurred!`);
    yield put(loginError(e));
  } finally {
    if (yield cancelled()) {
      // console.log("CANCELLED");
    }
  }
}

export function* resendEmail(action) {
  //console.log("resend email 164", action);

  try {
    yield resendEmailApi(action.payload);
    info("success", `Verification email has been resent`);
  } catch (e) {
    if (e.response) {
      info("error", `${e.response.data.error.message}`);
    }
  }
}

// export function* watchAuthentication() {
//   while (true) {
//     const { body, cb, extras } = yield take(LOGIN_REQUEST);
//     //console.log("TEST", email, password)
//     // fork return a Task object
//     const task = yield fork(authorize, body, cb, extras);
//     const action = yield take([LOGOUT, LOGIN_ERROR]);
//     if (action.type === LOGOUT) yield cancel(task);
//     //Clear local storage here or run callback
//     //yield call(Api.clearItem, 'token')
//   }
// }

export function* passwordResetRequest(action) {
  let res;
  try {
    // console.log("Actions: ", action);
    res = yield passwordResetApi(action.body);
    //console.log("FROM SAGA", res.data.data.message);
    info("success", `Email has been sent with verification code`);
    yield put(passwordResetSuccess(res));
    if (action.cb) {
      action.cb();
    }
  } catch (e) {
    if (e.response) {
      //console.log("FROM SAGA ERROR", e.response.data.error.message);
      info("error", `${e.response.data.error.message}`);
    }
    yield put(passwordResetError(e));
  } finally {
    if (yield cancelled()) {
      //console.log("CANCELLED");
    }
  }
}
export function* resendCodeRequest(action) {
  let res;
  try {
    res = yield resendCodeApi(action.body);
    //  console.log("FROM SAGA", res.data.data.message);
    info(
      "success",
      `Please check your inbox, Email has been sent with verification code`
    );
    yield put(resendCodeSuccess(res));
    if (action.cb) {
      action.cb();
    }
  } catch (e) {
    if (e.response) {
      // console.log("FROM SAGA ERROR", e.response.data.error.message);
      info("error", `${e.response.data.error.message}`);
    }
    yield put(resendCodeError(e));
  } finally {
    if (yield cancelled()) {
      // console.log("CANCELLED");
    }
  }
}
export function* verificationCode(action) {
  let res;
  try {
    // console.log("Verify action: ", action);
    res = yield verifyCodeApi(action.body);
    //  console.log("FROM SAGA", res.data.data);
    yield put(verificationCodeSuccess(res));
    info("success", `Your account has been verified`);
    if (action.cb) {
      action.cb();
    }
  } catch (e) {
    if (e.response) {
      //  console.log("FROM SAGA ERROR", e.response.data.error.message);
      info("error", `${e.response.data.error.message}`);
    }
    yield put(verificationCodeError(e));
  } finally {
    if (yield cancelled()) {
      // console.log("CANCELLED");
    }
  }
}
export function* changePassword(action) {
  //console.log("chnage password::", action.body);
  let res;
  try {
    let { ...properBody } = action.body;
    // console.log("properBody::", properBody);
    res = yield resetPasswordApi(properBody);
    info("success", `Password Updated Successfully!`);
    //console.log("FROM SAGA", res.data.data);
    if (action.cb) {
      action.cb();
    }
    yield put(changePasswordSuccess(res));
  } catch (e) {
    if (e.response) {
      // console.log("FROM SAGA ERROR", e.response.data.error.message);
      info("error", `${e.response.data.error.message}`);
    }
    yield put(changePasswordError(e));
  } finally {
    if (yield cancelled()) {
      // console.log("CANCELLED");
    }
  }
}

// Our watcher Saga: spawn a new incrementAsync task on each INCREMENT_ASYNC
export function* watchAuthActions() {
  //yield fork(watchAuthentication);
  yield takeLatest(LOGIN_REQUEST, authorize);
  yield takeLatest(REGISTER_REQUEST, register);
  yield takeLatest(PASSWORD_RESET_REQUEST, passwordResetRequest);
  yield takeLatest(RESEND_CODE_REQUEST, resendCodeRequest);
  yield takeLatest(VERIFY_TOKEN_REQUEST, verificationCode);
  yield takeLatest(RESET_PASSWORD_REQUEST, changePassword);
  yield takeLatest(RESEND_EMAIL, resendEmail);
  yield takeLatest(SIGNUP_VALIDATION_REQUEST, signUpValidation);
}
