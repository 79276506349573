import React from 'react';
import { connect } from "react-redux";
import { Modal } from 'antd';

import { closeModal } from "../../actions/Modal"

const BasicModal = (props) => {
  return (
    <Modal
    centered
    closable
    zIndex={1009}
    className={props.className}
    maskStyle={{ background: '#ffffff80' }}
    // title={props.title}
    keyboard={true}
    visible={props.visible}
    footer={null}
    //okText={props.okText}
    //cancelText={props.cancelText}
    {...props}
    onOk={() => {
      props.dispatch(closeModal())
      props.onOk && props.onOk();
    }}
    onCancel={() => {
      props.dispatch(closeModal())
      props.onCancel && props.onCancel();
    }}
    >
      {props.content}
    </Modal>
  )
}

export default connect()(BasicModal);
