import { css } from "styled-components";

const baseStyle = css`
  color: ${(props) => props.theme[props.theme.mode].textColor.primary};
  font-size: ${(props) =>
    props.theme[props.theme.mode].textSizes.relative.body};
  font-weight: 400;

  letter-spacing: 0.021vw;
  line-height: 1.3;

  white-space: normal;
  word-break: break-word;

  .ant-input,
  .ant-input-affix-wrapper {
    height: auto;
    padding: 0.7vw 0.8vw;
    /* padding: 0.7vw 0.2vw; */
  }

  /* Typography */
  h1,
  h3,
  h4,
  h5 {
    color: ${(props) => props.theme[props.theme.mode].textColor.primary};
  }
  h1 {
    font-size: ${(props) =>
      props.theme[props.theme.mode].textSizes.relative.heading};
    font-weight: 700;
    line-height: 1.21;
    letter-spacing: 0.075vw;
  }
  h2 {
    //color: ${(props) => props.theme[props.theme.mode].textColor.primary};
    //color: ${(props) => props.theme[props.theme.mode].textColor.hint};
    font-size: ${(props) =>
      props.theme[props.theme.mode].textSizes.relative.subHeading};
    font-weight: 700;
    line-height: 1.22;
    letter-spacing: 0.06vw;
  }
  h3 {
    font-size: ${(props) =>
      props.theme[props.theme.mode].textSizes.relative.pageHeading};
    font-weight: 600;
    line-height: 1.2;
    letter-spacing: 0.034vw;
  }
  h4 {
    font-size: ${(props) =>
      props.theme[props.theme.mode].textSizes.relative.generalHeading};
    font-weight: 700;
    line-height: 1.2;
    letter-spacing: 0.027vw;
  }
  h5 {
    font-size: ${(props) =>
      props.theme[props.theme.mode].textSizes.relative.smallHeading};
    font-weight: 700;
    line-height: 1.2;
    letter-spacing: 0.027vw;
  }
  h6 {
    font-size: ${(props) =>
      props.theme[props.theme.mode].textSizes.relative.bodyLarge};
    font-weight: 700;
    line-height: 1.2;
    letter-spacing: 0.027vw;
  }
  p {
    font-size: ${(props) =>
      props.theme[props.theme.mode].textSizes.relative.body};
    font-weight: 400;
    line-height: 1.5;
    letter-spacing: 0.021vw;
  }
  input,
  .inputField[type],
  .inputField input,
  .selectField,
  .selectField.ant-select-dropdown-menu-item,
  .selectField.ant-select .ant-select-selection__rendered,
  textarea.inputField.ant-input,
  .ant-radio-button-wrapper * {
    font-size: ${(props) =>
      props.theme[props.theme.mode].textSizes.relative.captions};
    font-weight: 400;
    line-height: 1.3;
    letter-spacing: 0.021vw;
    //font-style: italic;
  }
  a {
    color: ${(props) => props.theme[props.theme.mode].secondary.main};
    font-size: ${(props) =>
      props.theme[props.theme.mode].textSizes.relative.links};
    //line-height: 1.46;
    letter-spacing: 0.0185vw;
    text-decoration: underline;
  }
  a.caption,
  .caption a {
    font-size: ${(props) =>
      props.theme[props.theme.mode].textSizes.relative.captions};
    text-decoration: none;
  }
  a:hover,
  a:focus,
  a:active {
    text-decoration: underline;
  }
  /* For Spacing classes Name */
  .inputField {
    margin-bottom: 4.5%;
  }
  .mr-t-sm {
    margin-top: 10px !important;
  }
  .mr-t {
    margin-top: 15px !important;
  }
  .mr-t-md {
    margin-top: 25px !important;
  }
  .mr-t-lg {
    margin-top: 65px !important;
  }
  .mr-b-sm {
    margin-bottom: 0.46rem !important;
  }
  .mr-b {
    margin-bottom: 15px !important;
  }
  .mr-b-md {
    margin-bottom: 25px !important;
  }
  .mr-b-lg {
    margin-bottom: 65px !important;
  }
  p {
    margin-bottom: 0;
    white-space: normal;
  }
  .no-mr {
    margin: 0 !important;
  }
  .no-pd {
    padding: 0 !important;
  }
  .pd-y {
    padding: 1rem 0;
  }

  /* Common classes */
  /* custom Colors */
  .dangerColor {
    color: ${(props) => props.theme[props.theme.mode].error.main};
    text-align: left;
    font-weight: 600;
  }
  .dangerBackgroundColor {
    background-color: ${(props) => props.theme[props.theme.mode].error.main};
    /* text-align: left;
    font-weight: 600; */
  }
  .successColor {
    color: #0da860;
  }

  .invertedColor {
    color: ${(props) => props.theme[props.theme.mode].textColor.inverted};
  }
  .hintColor {
    color: ${(props) => props.theme[props.theme.mode].textColor.hint};
  }
  .appColor {
    color: ${(props) => props.theme[props.theme.mode].primary.main};
  }
  .appBackground {
    background-color: ${(props) => props.theme[props.theme.mode].primary.main};
  }
  .secondaryAppColor {
    color: ${(props) => props.theme[props.theme.mode].secondary.main};
  }
  .secondaryAppBackColor {
    background-color: ${(props) =>
      props.theme[props.theme.mode].secondary.main};
  }
  .whiteColor {
    color: ${(props) => props.theme[props.theme.mode].common.white};
  }
  .ant-select-dropdown-menu {
    background-color: ${(props) =>
      props.theme[props.theme.mode].background.primary};
    color: ${(props) => props.theme[props.theme.mode].textColor.primary};
  }
  /* Alignment */
  .textLeft {
    text-align: left;
  }
  .textRight {
    text-align: right;
  }
  .textCenter {
    text-align: center;
  }
  .justifyCenter {
    justify-content: center;
  }
  .justifyBase {
    justify-content: baseline;
  }
  .justifyStart {
    justify-content: flex-start;
  }
  .justifyEnd {
    justify-content: flex-end;
  }

  /* Font Weights */
  .fontLight {
    font-weight: 300;
  }
  .fontNormal {
    font-weight: 400;
  }
  .font500 {
    font-weight: 500;
  }
  .fontMedium {
    font-weight: 600;
  }
  .fontBold {
    font-weight: 700;
  }

  .fullWidth {
    width: 100%;
  }
  .minHeight {
    min-height: 20rem;
  }
  /* Font Sizes */
  .large {
    font-size: ${(props) =>
      props.theme[props.theme.mode].textSizes.relative.banner};
    line-height: 1.2;
    letter-spacing: 0.125vw;
    word-break: normal;
  }
  .caption,
  .caption p {
    font-size: ${(props) =>
      props.theme[props.theme.mode].textSizes.relative.captions};
  }
  .medium {
    font-size: ${(props) =>
      props.theme[props.theme.mode].textSizes.relative.pageHeading};
    font-weight: 600;
  }
  .normal {
    font-size: ${(props) =>
      props.theme[props.theme.mode].textSizes.relative.body};
  }
  .small {
    font-size: ${(props) =>
      props.theme[props.theme.mode].textSizes.relative.links};
    line-height: 1.46;
    letter-spacing: 0.0185vw;
  }
  .button,
  .navigation-bar {
    font-size: ${(props) =>
      props.theme[props.theme.mode].textSizes.relative.btn};
    letter-spacing: 0.021vw;
  }
  .cursorPointer {
    cursor: pointer;
  }
  .cursorNotAllowed {
    cursor: not-allowed;
  }
  .inputField[type]::-webkit-input-placeholder,
  .inputField input::-webkit-input-placeholder,
  .placeholders,
  textarea.inputField.ant-input::-webkit-input-placeholder,
  .inputField[type]::placeholder,
  .inputField input::placeholder,
  .placeholders,
  textarea.inputField.ant-input::placeholder,
  .ant-select-selection-placeholder {
    font-size: ${(props) =>
      props.theme[props.theme.mode].textSizes.relative.captions};
    color: ${(props) => props.theme[props.theme.mode].textColor.hint};
    text-align: left;
    letter-spacing: 0.0185vw;
    font-weight: 600;
    opacity: 0.5;
  }
  /* For block border */
  .borderCircle {
    border-radius: 50%;
  }
  .borderRound {
    border-radius: ${(props) =>
      props.theme[props.theme.mode].card.borderRadius}px;
  }
  .borderSquare {
    border-radius: 0;
  }

  /* For image fit */
  .fitImage {
    object-fit: cover;
    height: 100%;
    width: 100%;
  }
  /* For Font Style */
  .fontItalic {
    font-style: italic;
  }
  .fontNormal {
    font-style: normal;
  }
  .fontOblique {
    font-style: oblique;
  }
  .allCaps {
    text-transform: uppercase;
  }
  .underlined {
    text-decoration: underline;
  }

  @media screen and (max-width: 991px) {
    font-size: calc(
      ${(props) => props.theme[props.theme.mode].textSizes.relative.body} - 4px
    );

    /* letter-spacing: 0.4px; */

    h1 {
      font-size: calc(
        ${(props) => props.theme[props.theme.mode].textSizes.relative.heading} -
          4px
      );
      letter-spacing: 1.44px;
    }
    h2 {
      font-size: calc(
        ${(props) =>
            props.theme[props.theme.mode].textSizes.relative.subHeading} - 4px
      );
      letter-spacing: 1.17px;
    }
    h3 {
      font-size: calc(
        ${(props) =>
            props.theme[props.theme.mode].textSizes.relative.pageHeading} - 4px
      );
      letter-spacing: 0.63px;
    }
    h4 {
      font-size: calc(
        ${(props) =>
            props.theme[props.theme.mode].textSizes.relative.generalHeading} -
          4px
      );
      letter-spacing: 0.5px;
    }
    h5 {
      font-size: calc(
        ${(props) =>
            props.theme[props.theme.mode].textSizes.relative.smallHeading} - 4px
      );
      letter-spacing: 0.4px;
    }
    h6 {
      font-size: ${(props) =>
        props.theme[props.theme.mode].textSizes.relative.bodyLarge};
      letter-spacing: 0.4px;
    }
    p {
      font-size: calc(
        ${(props) => props.theme[props.theme.mode].textSizes.relative.body} -
          4px
      );
      letter-spacing: 0.4px;
    }
    .large {
      font-size: calc(
        ${(props) => props.theme[props.theme.mode].textSizes.relative.banner} -
          4px
      );
      line-height: 1.2;
      letter-spacing: 0.125vw;
    }
    .caption,
    .caption p,
    a.caption,
    .caption a {
      font-size: ${(props) =>
        props.theme[props.theme.mode].textSizes.relative.captions};
    }
    a,
    .small {
      font-size: calc(
        ${(props) => props.theme[props.theme.mode].textSizes.relative.links} -
          4px
      );
      letter-spacing: 0.35px;
    }
    .button,
    .navigationBar {
      font-size: calc(
        ${(props) => props.theme[props.theme.mode].textSizes.relative.btn} - 4px
      );
      letter-spacing: 0.45px;
    }
    /* Font Sizes */
    input,
    .inputField[type],
    .inputField input,
    .selectField,
    .selectField.ant-select-dropdown-menu-item,
    .selectField.ant-select .ant-select-selection__rendered,
    textarea.inputField.ant-input,
    .inputField[type]::-webkit-input-placeholder,
    .inputField input::-webkit-input-placeholder,
    .placeholders,
    textarea.inputField.ant-input::-webkit-input-placeholder,
    .inputField[type]::placeholder,
    .inputField input::placeholder,
    .placeholders,
    textarea.inputField.ant-input::placeholder,
    .ant-select-selection-placeholder,
    .ant-radio-button-wrapper * {
      font-size: calc(
        ${(props) => props.theme[props.theme.mode].textSizes.relative.captions} -
          4px
      );
      letter-spacing: 0.38px;
    }
    .ant-input,
    .ant-input-affix-wrapper {
      padding: 0.5rem;
    }
    .ant-select-selection {
      //padding: calc(.5rem + 1px) .5rem;
      padding: calc(0.5rem) 0.5rem;
    }
  }
  @media screen and (max-width: 767px) {
    padding: 35px 20px;
    font-size: ${(props) =>
      props.theme[props.theme.mode].textSizes.actual.body};
    letter-spacing: 0.4px;
    h1 {
      font-size: ${(props) =>
        props.theme[props.theme.mode].textSizes.actual.heading};
      letter-spacing: 1.44px;
    }
    h2 {
      font-size: ${(props) =>
        props.theme[props.theme.mode].textSizes.actual.subHeading};
      letter-spacing: 1.17px;
    }
    h3 {
      font-size: ${(props) =>
        props.theme[props.theme.mode].textSizes.actual.pageHeading};
      letter-spacing: 0.63px;
    }
    h4 {
      font-size: ${(props) =>
        props.theme[props.theme.mode].textSizes.actual.generalHeading};
      letter-spacing: 0.5px;
    }
    h5 {
      font-size: ${(props) =>
        props.theme[props.theme.mode].textSizes.actual.smallHeading};
      letter-spacing: 0.4px;
    }
    h6 {
      font-size: ${(props) =>
        props.theme[props.theme.mode].textSizes.actual.bodyLarge};
      letter-spacing: 0.4px;
    }
    p,
    input,
    .inputField[type],
    .inputField input,
    .selectField,
    .selectField.ant-select-dropdown-menu-item,
    .selectField.ant-select .ant-select-selection__rendered,
    textarea.inputField.ant-input,
    .ant-radio-button-wrapper * {
      font-size: ${(props) =>
        props.theme[props.theme.mode].textSizes.actual.body};
      letter-spacing: 0.4px;
    }
    .large {
      font-size: ${(props) =>
        props.theme[props.theme.mode].textSizes.actual.banner};
      line-height: 1.2;
      letter-spacing: 0.125vw;
    }
    a,
    .small {
      font-size: ${(props) =>
        props.theme[props.theme.mode].textSizes.actual.links};
      letter-spacing: 0.35px;
    }
    .button,
    .navigationBar {
      font-size: ${(props) =>
        props.theme[props.theme.mode].textSizes.actual.btn};
      letter-spacing: 0.45px;
    }
    /* Font Sizes */
    .inputField[type]::-webkit-input-placeholder,
    .inputField input::-webkit-input-placeholder,
    .placeholders,
    textarea.inputField.ant-input::-webkit-input-placeholder,
    .ant-select-selection-placeholder {
      font-size: ${(props) =>
        props.theme[props.theme.mode].textSizes.actual.captions};
      letter-spacing: 0.38px;
    }
  }
`;

export default baseStyle;
