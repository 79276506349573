import axios from "axios";
import { API_REST_URL, LOCAL_STORAGE_KEY } from "./Constants";
import { logout } from "../utils";
//import { browserHistory } from "react-router-dom";

const headers = {
  "Access-Control-Allow-Origin": "*",
  "Access-Control-Allow-Methods": "POST, GET, OPTIONS, PATCH, DELETE",
  "Content-Type": "application/json",
};

function resetHeaders(agent) {
  agent.defaults.headers = headers;
}

//Setting user token on app load could be done elsewhere, maybe on shell mount or routes isLogin check
let authDetails;

if (localStorage.getItem(LOCAL_STORAGE_KEY)) {
  authDetails = JSON.parse(window.localStorage.getItem(LOCAL_STORAGE_KEY));
}

const publicAgent = axios.create({
  baseURL: API_REST_URL,
  headers: headers,
});

const privateAgent = axios.create({
  baseURL: API_REST_URL,
  headers: {
    ...headers,
    "Authorization": authDetails ? "Bearer " + authDetails.access_token : "",
    //user_id: authDetails ? authDetails.user_id : "",

    //"eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJkYXRhIjp7ImlkIjo1NSwiZmlyc3ROYW1lIjoiU2FtZWVyIiwiZW1haWwiOiJvdnVuZGVydXNlckB5b3BtYWlsLmNvbSJ9LCJpYXQiOjE1NzUzODgzMzAsImV4cCI6MTU3NTk5MzEzMH0._kqD4mDJxKnRq3DGm2FwXPKqYtkkAFU9ILUqG1UpdoM"
  },
});

privateAgent.interceptors.response.use(null, (error) => {
  //console.log("INTERCEPTOR", error.response.status);
  if (error.response && error.response.status === 401) {
    //log user out, clear local storage, clear agent headers and redirect to login screen
    logout();
    //window.location = '/asdasdasd'
  }
  //browserHistory.push
  return Promise.reject(error);
});

const CancelToken = axios.CancelToken;

export { headers, resetHeaders, publicAgent, privateAgent, CancelToken };
