import React from "react";
import PropTypes from "prop-types";

import { LoadingOutlined } from "@ant-design/icons";
import styled from "styled-components";

const Container = styled.div`
  & {
    //height: 100%;
    text-align: center;
    //position: fixed;
    //margin: 30vh 0;
    ${props => !props.margin ? "flex-grow: 1" : `margin: ${props.margin}`};
    display: flex;
    align-items: center;
    justify-content: center;
    // top: 50%;
    // left: 50%;
    // right: 50%;
    // bottom: 50%;
    // transform: translate(-50px, -50px);
  }
  svg {
    font-size: ${props => props.size};
  }
`;

const Loader = props => {
  const { margin, size } = props;
  return (
    <Container margin={margin} size={size || "3rem"} >
      <LoadingOutlined />
    </Container>
  );
};

Loader.propTypes = {
  margin: PropTypes.number,
  size: PropTypes.string
}

export default Loader;
